import { Organization } from '@pipefyteam/types.base-types';

import gql from 'graphql-tag';

export type GetOrganization = {
  organization: Pick<
    Organization,
    | 'id'
    | 'name'
    | 'role'
    | 'uuid'
    | 'permissions'
    | 'planName'
    | 'daysToExpirePlan'
  > & {
    freemium: boolean;
  };
};

export type GetOrganizationVariables = Pick<Organization, 'id'>;

export default gql`
  query Organization($id: ID!) {
    organization(id: $id) {
      id
      name
      role
      uuid
      freemium
      daysToExpirePlan
      planName
      permissions {
        canBecomeGroupOwner
        canCreateGroups
        canHidePipefyBranding
        canManageBranding
        canManageCompanySettings
        canManageRoles
        canManageEmailSettings
        canManagePlatformApp
        canManageServiceAccounts
        canManageAiProviders
        canShowBilling
        canManageTags
        canShowMembersAndPermissions
        canShowRoles
        canCreatePortals
        canBecomePortalAdmin
        inviteMember
        showAdminPanel
        showCompanyActivities
        showDashboard
        showGroups
        showTags
        showInviteMember
        showUsageStats
        showUsersGroups
      }
    }
  }
`;
