import React, { ReactElement } from 'react';

import logger from '@pipefyteam/utils.logger';

import {
  LazyQueryHookOptions,
  QueryLazyOptions,
  QueryTuple,
  useMutation as useMutationOld,
  useQuery as useQueryOld,
  useLazyQuery as useLazyQueryOld,
} from '@apollo/react-hooks';
import {
  MockedResponse,
  MockedProvider as MockedProviderOld,
  MockedProviderProps,
} from '@apollo/react-testing';
import { DataProxy, ApolloCache } from 'apollo-cache';
import {
  InMemoryCache,
  NormalizedCacheObject,
  IntrospectionFragmentMatcher,
  IntrospectionResultData,
} from 'apollo-cache-inmemory';
import ApolloClient, {
  NetworkStatus,
  ApolloError,
  ApolloQueryResult,
  MutationUpdaterFn,
  PureQueryOptions,
  FetchMoreOptions,
} from 'apollo-client';
import { ApolloLink, split, DocumentNode } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { withClientState } from 'apollo-link-state';
import { toIdValue } from 'apollo-utilities';
import {
  QueryResult,
  MutationResult,
  MutationFetchResult,
  MutationFunctionOptions,
  ApolloProvider as Provider,
  MutationFunction,
  Mutation,
  Query,
  useApolloClient,
  withApollo,
  MutationTuple,
  MutationHookOptions,
  QueryHookOptions,
  ApolloConsumer,
  ExecutionResult,
  BaseMutationOptions,
  graphql,
  OperationVariables,
} from 'react-apollo';

// eslint-disable-next-line no-restricted-imports
import { client } from 'utils/apolloClient';

const ApolloProvider = ({
  children,
  customClient = client,
}: {
  children: ReactElement;
  customClient?: ApolloClient<NormalizedCacheObject>;
}): ReactElement => {
  return <Provider client={customClient}>{children}</Provider>;
};

const MockedProvider = (props: MockedProviderProps): ReactElement => {
  return <MockedProviderOld {...props}>{props.children}</MockedProviderOld>;
};

const handleApolloError = (error: ApolloError): void => {
  error.message = `APOLLO-MIGRATION: ${error?.message || ''}`;
  logger.logError(error);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  return useQueryOld(query, {
    ...options,
    onError: (error) => {
      handleApolloError(error);
      options?.onError?.(error);
    },
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useLazyQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: LazyQueryHookOptions<TData, TVariables>
): QueryTuple<TData, TVariables> {
  return useLazyQueryOld(query, {
    ...options,
    onError: (error) => {
      handleApolloError(error);
      options?.onError?.(error);
    },
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useMutation<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> {
  return useMutationOld(query, {
    ...options,
    onError: (error) => {
      handleApolloError(error);
      options?.onError?.(error);
    },
  });
}

export {
  ApolloProvider,
  MockedProvider,
  useMutation,
  useQuery,
  useLazyQuery,
  Mutation,
  Query,
  useApolloClient,
  withApollo,
  ApolloConsumer,
  graphql,
  toIdValue,
  setContext,
  onError,
  createHttpLink,
  withClientState,
  split,
  client,
  InMemoryCache,
  IntrospectionFragmentMatcher,
  ApolloClient,
  NetworkStatus,
  ApolloError,
};

export type {
  ApolloLink,
  QueryResult,
  MutationResult,
  MutationFetchResult,
  MutationFunctionOptions,
  QueryLazyOptions,
  MutationTuple,
  MutationHookOptions,
  QueryHookOptions,
  IntrospectionResultData,
  ExecutionResult,
  BaseMutationOptions,
  MutationFunction,
  MockedResponse,
  ApolloQueryResult,
  MutationUpdaterFn,
  PureQueryOptions,
  FetchMoreOptions,
  NormalizedCacheObject,
  DocumentNode,
  DataProxy,
  ApolloCache,
};
